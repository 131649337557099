import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteItem } from '@app/core/contracts/common';
import { PendingRequestsInterceptor } from '@app/core/interceptors/pending-requests-interceptor';
import { AuthService } from '@app/core/services/auth.service';
import { SiteSettingsService } from '@app/core/services/site-settings.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './app.html',
  styleUrls: ['./app.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ transform: 'translateX(-100%)' }), animate(200)]),
      transition('* => void', [animate(200, style({ transform: 'translateX(100%)' }))])
    ]),
    trigger('flyInBack', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ transform: 'translateX(-100%)' }), animate(200)]),
      transition('* => void', [animate(200, style({ transform: 'translateX(-100%)' }))])
    ])
  ]
})
export class AppLayoutComponent implements OnInit {
  public smallNav = true;
  public showNav = true;
  public appVersion: string = environment.version;
  public isLoading$: Observable<boolean>;
  public expanded = true;
  public routes: RouteItem[] = [
    {
      name: 'Dashboard',
      isGroup: true,
      children: [
        {
          name: 'All Channels',
          icon: 'dashboard',
          route: '/secure/dashboard/all'
        },
        {
          name: 'Cinema',
          icon: 'tv',
          route: '/secure/dashboard/cinema'
        },
        {
          name: 'Digital',
          icon: 'computer',
          route: '/secure/dashboard/digital'
        },
        {
          name: 'Magazine',
          icon: 'book',
          route: '/secure/dashboard/magazine'
        },
        {
          name: 'Newspaper',
          icon: 'import_contacts',
          route: '/secure/dashboard/newspaper'
        },
        {
          name: 'OOH',
          icon: 'add_business',
          route: '/secure/dashboard/ooh'
        },
        {
          name: 'Radio',
          icon: 'radio',
          route: '/secure/dashboard/radio'
        },
        {
          name: 'TV',
          icon: 'tv',
          route: '/secure/dashboard/tv'
        }
      ]
    },
    {
      name: 'Reporting',
      isGroup: true,
      children: [
        {
          name: 'Operational',
          icon: 'admin_panel_settings',
          route: '/secure/reports/operational'
        },
        {
          name: 'Financial',
          icon: 'account_balance_wallet',
          route: '/secure/reports/financial'
        }
      ]
    }
  ];

  constructor(private router: Router, public authService: AuthService, private pendingRequestsInterceptor: PendingRequestsInterceptor, public siteSettingsService: SiteSettingsService) {
    this.isLoading$ = this.pendingRequestsInterceptor.pendingRequestsStatus$;
  }

  ngOnInit() {}

  redirectHome() {
    this.router.navigate(['./secure/dashboard/all']);
  }

  signOut() {
    this.authService.logout();
    this.router.navigate(['']);
  }

  expandRoute(route: any) {
    if (route.children && route.children.length > 0) {
      route.expanded = !route.expanded;
    }
  }

  get toolbarHeader() {
    let routeName = 'Dashboard / All Channels';

    this.routes.forEach((route) => {
      route.children.forEach((routeChild) => {
        if (this.router.url.includes(routeChild.route)) {
          routeName = `${route.name} / ${routeChild.name}`;
        }
      });
    });
    return routeName;
  }
}
