<h2 class="g-marg-b-20">{{headerText}}</h2>

<div class="g-w-full" fxLayout="column">
  <mat-form-field appearance="outline">
    <mat-label>Brand</mat-label>
    <mat-select (selectionChange)="loadProducts()" [(ngModel)]="selectedBrand" name="brand">
      <mat-option *ngFor="let brand of brands" [value]="brand"> {{brand}} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Product</mat-label>
    <mat-select (selectionChange)="loadSubProducts()" [(ngModel)]="selectedProduct" name="product">
      <mat-option *ngFor="let product of products" [value]="product"> {{product}} </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>SubProduct</mat-label>
    <mat-select (selectionChange)="loadBurstRange()" [(ngModel)]="selectedSubProduct" name="subProduct">
      <mat-option *ngFor="let subproduct of subProducts" [value]="subproduct"> {{subproduct}} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Burst date Ranges</mat-label>
    <mat-select [(ngModel)]="selectedBurstRange" name="burstRange">
      <mat-option *ngFor="let burstRange of burstRanges" [value]="burstRange"> {{burstRange.burstFromDate | date}} - {{burstRange.burstToDate | date}} </mat-option>
    </mat-select>
  </mat-form-field>

  <div fxLayout="row">
    <button class="g-w-full" mat-flat-button color="primary" (click)="close()">Cancel</button>
    <div fxFlex="10px"></div>
    <button [disabled]="!selectedBurstRange" class="g-w-full" mat-flat-button color="accent" (click)="filterData()">{{actionButtonText}}</button>
  </div>
</div>
