import { Injectable } from '@angular/core';
import { DwAllMediaSpendRequest, DwMonthlySummaryReportRequest, DwWeeklySummaryReportRequest, IGraphFilter } from '../contracts/contracts';
import { ReportProxy } from '../proxies/report.proxy';

@Injectable()
export class ReportService {
  constructor(private reportProxy: ReportProxy) {}

  public getPIBReport(body: IGraphFilter) {
    return this.reportProxy.getPIBReport(body);
  }

  public getAllSpendMediaReport(body: DwAllMediaSpendRequest) {
    return this.reportProxy.getAllSpendMediaReport(body);
  }

  public getMonthlySummaryReport(body: DwMonthlySummaryReportRequest) {
    return this.reportProxy.getMonthlySummaryReport(body);
  }

  public getWeeklySummaryReport(body: DwWeeklySummaryReportRequest) {
    return this.reportProxy.getWeeklySummaryReport(body);
  }
}
