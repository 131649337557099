import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DwClientBrands, DwClientProducts, DwClients, GraphDataType, GraphDataTypeList, IGraphFilter } from '../contracts/contracts';

@Injectable({ providedIn: 'root' })
export class AdvertiserProxy {
  constructor(private http: HttpClient) {}

  public getBrands(type: string) {
    const url = `tv/advertiser/`;
    return this.http.get<string[]>(url);
  }

  public getProducts(type: string, brand: string) {
    const url = `tv/products/${type}?brand=${brand}`;
    return this.http.get<string[]>(url);
  }

  public getSubProducts(type: string, product: string) {
    const url = `tv/subproducts/${type}?product=${product}`;
    return this.http.get<string[]>(url);
  }

  public getGRPByWeek(filter: IGraphFilter) {
    const url = `tv/grp/week`;
    return this.http.post<GraphDataTypeList[]>(url, JSON.stringify(filter));
  }

  public getReachPercByWeek(filter: IGraphFilter) {
    const url = `tv/reach/week`;
    return this.http.post<GraphDataTypeList[]>(url, JSON.stringify(filter));
  }

  public getGRPByStation(filter: IGraphFilter) {
    const url = `tv/grp/station`;
    return this.http.post<GraphDataTypeList[]>(url, JSON.stringify(filter));
  }

  public getGRPByPIBPerc(filter: IGraphFilter) {
    const url = `tv/grp/pib`;
    return this.http.post<GraphDataType[]>(url, JSON.stringify(filter));
  }

  public getGRPByDayPart(filter: IGraphFilter) {
    const url = `tv/grp/daypart`;
    return this.http.post<GraphDataType[]>(url, JSON.stringify(filter));
  }

  public getClients() {
    const url = `clients`;
    return this.http.get<DwClients[]>(url);
  }

  public getClientBrands(businessEntityId: number) {
    const url = `clients/` + businessEntityId + `/brands`;
    return this.http.get<DwClientBrands[]>(url);
  }

  public getClientProducts(brandId: number) {
    const url = `clients/brands/` + brandId + `/products`;
    return this.http.get<DwClientProducts[]>(url);
  }
}
