import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BurstRange, IGraphFilter } from '@app/core/contracts/contracts';
import { DashboardService } from '@app/core/services/dashboard.service';
import { TVService } from '@app/core/services/tv.service';

@Component({
  selector: 'app-graph-tv-filters',
  templateUrl: './graph-tv-filters.html',
  styleUrls: []
})
export class GraphTVFiltersComponent implements OnInit {
  brands: string[];
  products: string[];
  subProducts: string[];
  burstRanges: BurstRange[];

  selectedBrand = '';
  selectedProduct = '';
  selectedSubProduct = '';
  selectedBurstRange: BurstRange;

  currentFilter: IGraphFilter;

  type = 'details';
  actionButtonText = 'Filter';
  headerText = 'Graph filter';

  constructor(private dialogRef: MatDialogRef<GraphTVFiltersComponent>, private tvService: TVService, private dashboardSerivce: DashboardService, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    if (this.data !== null && this.data.filter) {
      this.currentFilter = this.data.filter;
    }
    if (this.data !== null && this.data.overrideAction) {
      this.actionButtonText = this.data.overrideAction;
    }

    if (this.data !== null && this.data.headerText) {
      this.headerText = this.data.headerText;
    }

    this.loadBrands();
  }

  loadBrands() {
    this.tvService.getBrands(this.type).subscribe((brands) => {
      this.brands = brands;
    });
  }

  loadProducts() {
    this.tvService.getProducts(this.type, this.selectedBrand).subscribe((products) => {
      this.products = products;
    });
  }

  loadSubProducts() {
    this.tvService.getSubProducts(this.type, this.selectedProduct).subscribe((subProducts) => {
      this.subProducts = subProducts;
    });
  }

  loadBurstRange() {
    this.dashboardSerivce.getBurstRangeCombinations(this.selectedBrand, this.selectedProduct, this.selectedSubProduct).subscribe((burstRanges) => {
      this.burstRanges = burstRanges;
    });
  }

  filterData() {
    const filter = {
      brandName: this.selectedBrand,
      productName: this.selectedProduct,
      subProductName: this.selectedSubProduct,
      burstFromDate: this.selectedBurstRange.burstFromDate,
      burstFromTo: this.selectedBurstRange.burstToDate
    } as IGraphFilter;

    this.dialogRef.close(filter);
  }

  close() {
    this.dialogRef.close();
  }
}
