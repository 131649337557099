import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UnsubscribeBaseComponent } from '@app/core/components/base';
import { PendingRequestsInterceptor } from '@app/core/interceptors/pending-requests-interceptor';
import { AuthService } from '@app/core/services/auth.service';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'login-layout',
  templateUrl: './login.html',
  styleUrls: ['./login.scss']
})
export class LoginLayoutComponent extends UnsubscribeBaseComponent implements OnInit, OnDestroy {
  public authForm: FormGroup;
  public error = '';
  public isLoading$: Observable<boolean>;

  private returnUrl: string;

  constructor(private authService: AuthService, private builder: FormBuilder, private router: Router, private route: ActivatedRoute, private pendingRequestsInterceptor: PendingRequestsInterceptor) {
    super();
    this.isLoading$ = this.pendingRequestsInterceptor.pendingRequestsStatus$;
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/secure/dashboard';
    this.authForm = this.builder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  login() {
    if (this.authForm.valid) {
      this.authService
        .login(this.authForm.value)
        .pipe(
          map(() => {
            this.router.navigateByUrl('/secure/dashboard/all');
          })
        )
        // .pipe(takeUntil(this.onDestroy))
        .subscribe(
          () => {},
          () => {
            this.error = 'Incorrect Username / Password';
            setTimeout(() => {
              this.error = '';
            }, 5000);
          }
        );
    } else {
      this.error = 'Username & Password is required';
      setTimeout(() => {
        this.error = '';
      }, 5000);
    }
  }
}
