import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router) {}

  inflightAuthRequest: any;
  contentType = 'application/json';
  blacklist: any = [/accounts\/login/, /token\/refresh/];
  avoidContentTypeBlackList: any = [/assets\/product/];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('Content-Type')) {
      this.contentType = req.headers.get('Content-Type');
    }

    const avoidContent = this.blacklistCheckup(req.url, this.avoidContentTypeBlackList);

    if (avoidContent || this.blacklistCheckup(req.url, this.blacklist) || req.headers.get('authExempt') === 'true') {
      const headers = {
        setHeaders: {
          'Content-Type': this.contentType,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        }
      };

      if (avoidContent) {
        delete headers.setHeaders['Content-Type'];
      }

      const headerReq = req.clone(headers);

      return next.handle(headerReq);
    }

    const authService = this.injector.get(AuthService);

    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = authService.getToken();
    }

    if (!this.inflightAuthRequest) {
      // remove existing tokens
      sessionStorage.clear();
      this.router.navigate(['']);
      return next.handle(req);
    }

    return this.inflightAuthRequest.pipe(
      switchMap((newToken: string) => {
        // unset request inflight
        this.inflightAuthRequest = null;

        // use the newly returned token
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${newToken}`,
            'Content-Type': this.contentType,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
          }
        });

        return next.handle(authReq);
      }),
      catchError((error) => {
        // checks if a url is to an admin api or not
        if (error.status === 0) {
          throwError(error);
        }

        if (error.status === 401) {
          // If unauthorized from token then user auth required again.
          if (error.url.includes('token/refresh')) {
            sessionStorage.clear();
            this.router.navigate(['']);
            return throwError(error);
          }

          if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = authService.refreshToken();
            if (!this.inflightAuthRequest) {
              // remove existing tokens
              sessionStorage.clear();
              this.router.navigate(['']);
              return throwError(error);
            }
          }

          return this.inflightAuthRequest.pipe(
            switchMap((newToken: string) => {
              // unset inflight request
              this.inflightAuthRequest = null;

              // clone the original request
              const authReqRepeat = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${newToken}`,
                  'Content-Type': this.contentType,
                  'Cache-Control': 'no-cache',
                  Pragma: 'no-cache'
                }
              });

              // resend the request
              return next.handle(authReqRepeat);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }

  blacklistCheckup($url: string, blacklist: any): boolean {
    let returnValue = false;
    Object.keys(blacklist).forEach((i) => {
      if (blacklist[i].exec($url) != null) {
        returnValue = true;
        return;
      }
    });
    return returnValue;
  }
}
