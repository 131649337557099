import { Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app/app';
import { LoginLayoutComponent } from './layout/login/login';
import { UnauthorizedComponent } from './unauthorized.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: LoginLayoutComponent
  },
  {
    path: 'secure',
    component: AppLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./features/reports/reports.module').then((m) => m.ReportsModule)
      }
    ]
  },
  {
    path: 'auth-callback',
    loadChildren: () => import('./features/auth-callback/auth-callback.module').then((m) => m.AuthCallbackModule)
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', component: AppLayoutComponent }
];
