import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  template: `
    <div class="startup">
      <!-- <img class="img" src="" /> -->
      <h4>Page not found</h4>
      <br />
      <div style="width:200px" fxLayout="row" fxLayoutAlign="space-around start">
        <button (click)="back()" mat-raised-button color="primary">Back</button>
        <button (click)="home()" mat-raised-button color="primary">Home Page</button>
      </div>
    </div>
  `
})
export class PageNotFoundComponent {
  constructor(private router: Router, private location: Location) {}

  home() {
    const context = JSON.parse(sessionStorage.getItem('user_context'));
    if (context) {
      this.router.navigate(['./secure/welcome']);
    } else {
      this.router.navigate(['']);
    }
  }

  back() {
    this.location.back();
  }
}
