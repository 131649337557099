import { JwtHelperService } from '@auth0/angular-jwt';
import { ErrorSnackBarComponent } from './components/error-snack-bar/error-snack-bar';
import { ClientService } from './services/client.service';
import { AuthGuard } from './services/auth-guard';
import { AuthService } from './services/auth.service';
import { DashboardService } from './services/dashboard.service';
import { DownloadCsvService } from './services/download-csv.service';
import { ReportService } from './services/report.service';
import { SiteSettingsService } from './services/site-settings.service';
import { TVService } from './services/tv.service';

export const featureServices = [JwtHelperService, ErrorSnackBarComponent, AuthService, AuthGuard, ClientService, DashboardService, TVService, ReportService, DownloadCsvService, SiteSettingsService];

export const featureDialogs: any[] = [];

export const featureComponents: any[] = [ErrorSnackBarComponent];

export const featureDirectives: any[] = [];

export const featurePipes: any[] = [];
