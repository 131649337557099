<div class="mat-typography" class="g-h-full" fxLayout="column" fxLayoutAlign="start stretch">
  <mat-drawer-container class="g-h-full" [hasBackdrop]="false" autosize>
    <mat-drawer
      *ngIf="siteSettingsService.currentPlatformView !== siteSettingsService.platformViews.IFrame"
      color="primary"
      class="sidenav-container mat-elevation-z2 width-300"
      mode="side"
      opened="true"
      #sideNav
    >
      <div class="g-h-full" fxLayout="column">
        <span class="logo-wrapper" fxLayout="column" fxLayoutAlign="center center">
          <img (click)="redirectHome()" *ngIf="expanded" class="logo-image" src="/assets/images/MC_logo.png" />
          <img (click)="redirectHome()" *ngIf="!expanded" class="logo-image-small" src="/assets/images/small-icon.png" />
        </span>

        <div class="g-h-full" fxLayout="row" fxLayoutAlign="start stretch">
          <div class="g-h-full g-w-full" fxLayout="column" fxLayoutAlign="start stretch">
            <div class="route-item-wrapper" *ngFor="let route of routes" fxLayout="column" fxLayoutAlign="start stretch">
              <div class="route-group-item" *ngIf="route.isGroup"><span *ngIf="expanded">{{route.name}}</span></div>

              <div *ngIf="!route.isGroup" fxLayout="row" fxLayoutAlign="center center" class="router-links g-no-outline" [@flyInOut]="'in'" routerLinkActive="active" [routerLink]="route.route">
                <span class="menu-text" *ngIf="expanded"> {{route.name}} </span>
                <span class="menu-text" *ngIf="!expanded">
                  <mat-icon>{{ route.icon }}</mat-icon>
                </span>
              </div>

              <div class="child-routes">
                <div
                  class="router-links g-no-outline g-marg-b-10"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  *ngFor="let childRoute of route.children"
                  [@flyInOut]="'in'"
                  routerLinkActive="active"
                  [routerLink]="childRoute.route"
                >
                  <span class="menu-text" *ngIf="expanded"> {{childRoute.name}} </span>
                  <span class="menu-text" *ngIf="!expanded">
                    <mat-icon>{{ childRoute.icon }}</mat-icon>
                  </span>
                </div>
              </div>
            </div>
            <div [fxLayout]="expanded ? 'row' : 'column'" fxLayoutAlign="space-between center" class="g-marg-t-a g-marg-b-10">
              <div class="router-links accent g-no-outline" fxLayout="row" fxLayoutAlign="start center" (click)="signOut()">
                <span class="menu-text g-marg-l-10" *ngIf="expanded">Sign out</span>
                <div class="g-marg-l-5" *ngIf="!expanded">
                  <button mat-icon-button>
                    <mat-icon>exit_to_app</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <mat-toolbar *ngIf="siteSettingsService.currentPlatformView === siteSettingsService.platformViews.IFrame" color="primary">
        <mat-toolbar-row>
          <span>{{toolbarHeader}}</span>
          <span class="spacer"></span>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
          </button>
        </mat-toolbar-row>
      </mat-toolbar>

      <mat-menu #menu="matMenu">
        <div class="route-item-wrapper" *ngFor="let route of routes" fxLayout="column" fxLayoutAlign="start stretch">
          <div class="route-group-item" *ngIf="route.isGroup"><span *ngIf="expanded">{{route.name}}</span></div>

          <div class="child-routes">
            <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let childRoute of route.children">
              <button mat-menu-item [routerLink]="childRoute.route" routerLinkActive="menu-item-active">
                <mat-icon>{{ childRoute.icon }}</mat-icon>
                <span>{{childRoute.name}}</span>
              </button>
            </div>
          </div>
        </div>
      </mat-menu>

      <div class="my-content">
        <div class="container-body">
          <div class="container-body-content" fxLayout="row" fxLayoutAlign="start stretch">
            <div class="g-w-full">
              <div class="g-loading-shade" *ngIf="isLoading$ | async">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-spinner color="accent"></mat-spinner>
                </div>
              </div>
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
