import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ILoginIn, IUserContext } from '../contracts/contracts';
import { AccountsProxy } from '../proxies/accounts.proxy';
@Injectable()
export class AuthService {
  error: string;
  userContext: IUserContext;

  constructor(private jwtHelper: JwtHelperService, private accountsProxy: AccountsProxy) {
    this.userContext = JSON.parse(sessionStorage.getItem('mc_user_context') as string);
  }

  public authenticated() {
    let ex: boolean = !this.jwtHelper.isTokenExpired(this.jwtHelper.tokenGetter());
    if (ex && this.userContext) {
      return of(true);
    } else {
      const refreshToken = sessionStorage.getItem('refresh_token');
      if (refreshToken) {
        return this.accountsProxy.ping().pipe(
          map(() => {
            ex = !this.jwtHelper.isTokenExpired(this.jwtHelper.tokenGetter());
            return ex && this.userContext == null;
          })
        );
      } else {
        return of(false);
      }
    }
  }

  public login(credentials: ILoginIn) {
    return this.accountsProxy.login(credentials).pipe(
      map((res) => {
        sessionStorage.setItem('id_token', res.token);
        sessionStorage.setItem('refresh_token', res.refreshToken);
        sessionStorage.setItem('mc_user_context', JSON.stringify(res.userContext));
        this.userContext = res.userContext;
        return res;
      })
    );
  }

  public logout() {
    this.userContext = null;
    sessionStorage.removeItem('id_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('mc_user_context');
  }

  public getAuthorizationHeaderValue(): string {
    const token = sessionStorage.getItem('id_token');
    if (token) {
      return `Bearer ${token}`;
    }
  }

  public refreshToken() {
    // append refresh token if you have one
    const refreshToken = sessionStorage.getItem('refresh_token');
    if (!refreshToken) {
      return undefined;
    }
    return this.accountsProxy.refreshToken(refreshToken).pipe(
      share(),
      map((res) => {
        // store the new tokens
        // sessionStorage.setItem('refresh_token', res.refreshToken);
        sessionStorage.setItem('id_token', res.token);
        sessionStorage.setItem('mc_user_context', JSON.stringify(res.userContext));
        return res.token;
      })
    );
  }

  public getToken() {
    const token = sessionStorage.getItem('id_token') as string;
    const isTokenExpired = this.jwtHelper.isTokenExpired(token);

    if (!isTokenExpired) {
      return of(token);
    }
    return this.refreshToken();
  }
}
