import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DwAllMediaSpendRequest, DwMonthlySummaryReportRequest, DwTVFileDetailDto, DwWeeklySummaryReportRequest, IGraphFilter } from '../contracts/contracts';

@Injectable({ providedIn: 'root' })
export class ReportProxy {
  contentHeader: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) {}

  public getPIBReport(body: IGraphFilter) {
    const url = `reports/pib`;
    return this.http.post<DwTVFileDetailDto[]>(url, JSON.stringify(body));
  }

  public getAllSpendMediaReport(body: DwAllMediaSpendRequest) {
    const spendUrl = `reports/summary/all`;

    return this.http
      .post(spendUrl, JSON.stringify(body), {
        observe: 'response',
        headers: this.contentHeader,
        responseType: 'arraybuffer'
      })
      .pipe(
        map((response) => {
          const blob = new Blob([response.body]);
          const url = window.URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');

          // download file in IE browser versions
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, body.fileName);
          } else if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, body.fileName);
          }

          // download file in non-IE browsers
          downloadLink.download = body.fileName;
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          window.URL.revokeObjectURL(downloadLink.href);
        }),
        catchError((err) => {
          console.error(err.message);
          return of(err.message);
        })
      );
  }

  public getMonthlySummaryReport(body: DwMonthlySummaryReportRequest) {
    const monthUrl = `reports/summary/monthly`;

    return this.http
      .post(monthUrl, JSON.stringify(body), {
        observe: 'response',
        headers: this.contentHeader,
        responseType: 'arraybuffer'
      })
      .pipe(
        map((response) => {
          const blob = new Blob([response.body]);
          const url = window.URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');

          // download file in IE browser versions
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, body.fileName);
          } else if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, body.fileName);
          }

          // download file in non-IE browsers
          downloadLink.download = body.fileName;
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          window.URL.revokeObjectURL(downloadLink.href);
        }),
        catchError((err) => {
          console.error(err.message);
          return of(err.message);
        })
      );
  }

  public getWeeklySummaryReport(body: DwWeeklySummaryReportRequest) {
    const monthUrl = `reports/summary/weekly`;

    return this.http
      .post(monthUrl, JSON.stringify(body), {
        observe: 'response',
        headers: this.contentHeader,
        responseType: 'arraybuffer'
      })
      .pipe(
        map((response) => {
          const blob = new Blob([response.body]);
          const url = window.URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');

          // download file in IE browser versions
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, body.fileName);
          } else if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, body.fileName);
          }

          // download file in non-IE browsers
          downloadLink.download = body.fileName;
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          window.URL.revokeObjectURL(downloadLink.href);
        }),
        catchError((err) => {
          console.error(err.message);
          return of(err.message);
        })
      );
  }
}
