import { Injectable } from '@angular/core';
import { AdvertiserProxy } from '../proxies/advertiser.proxy';

@Injectable()
export class ClientService {
  constructor(private advertiserProxy: AdvertiserProxy) {}

  public getClients() {
    return this.advertiserProxy.getClients();
  }

  public getClientBrands(businessEntityId: number) {
    return this.advertiserProxy.getClientBrands(businessEntityId);
  }

  public getClientBrandProducts(brandId: number) {
    return this.advertiserProxy.getClientProducts(brandId);
  }
}
