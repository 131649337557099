<div class="login mat-typography" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
  <div class="login-card-container" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
    <form [formGroup]="authForm" class="login-form">
      <div fxLayout="column" fxLayoutAlign="center center">
        <img src="/assets/images/MC_logo.png" style="height: auto; width: 300px; text-align: center;" />
      </div>
      <mat-card-title>
        <h2>Business Insights</h2>
      </mat-card-title>
      <div fxFlex="10px"></div>
      <mat-form-field floatPlaceholder="never">
        <mat-icon matPrefix>person</mat-icon>
        <input autocomplete="username" matInput #email formControlName="email" type="text" placeholder="Email address" class="validate" />
      </mat-form-field>
      <div fxFlex="20px"></div>
      <mat-form-field floatPlaceholder="never">
        <mat-icon matPrefix>lock</mat-icon>
        <input autocomplete="current-password" matInput #password formControlName="password" type="password" placeholder="Password" class="validate" />
      </mat-form-field>
      <mat-card-actions class="error" *ngIf="error.length">
        {{error}}
      </mat-card-actions>
      <!-- <a mat-button
        class="link">Can`t access your account?</a> -->
      <div fxFlex="50px"></div>
      <button (click)="login()" class="submit-button" [disabled]="authForm.invalid" mat-raised-button>Sign in</button>
    </form>
  </div>
</div>
<div class="g-loading-shade" *ngIf="isLoading$ | async">
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
