import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgPipesModule } from 'ngx-pipes';
import { extModules, sharedComponents, sharedDirectives, sharedPipes } from './shared.declarations';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, NgPipesModule, NgxChartsModule, ...extModules],
  declarations: [...sharedComponents, ...sharedPipes, ...sharedDirectives],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, NgPipesModule, NgxChartsModule, ...sharedComponents, ...sharedDirectives, ...sharedPipes, ...extModules]
})
export class SharedModule {}
