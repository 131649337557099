import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DwClientBrands, DwClientProducts, DwClients, DwWeeklySummaryReportRequest } from '@app/core/contracts/contracts';
import { FlowplanType } from '@app/core/enums/enums';
import { ClientService } from '@app/core/services/client.service';

@Component({
  selector: 'app-weekly-summary-report-filters',
  templateUrl: './weekly-summary-report-filters.html',
  styleUrls: []
})
export class WeeklySummaryReportFilterComponent implements OnInit {
  advertisers: DwClients[] = [];
  brands: DwClientBrands[];
  products: DwClientProducts[];
  reportRequest: DwWeeklySummaryReportRequest;
  selectedAdvertiser = 0;
  selectedBrand = 0;
  selectedProduct = 0;
  selectedYear = 0;
  selectedFlowPlanType = '';
  validRequest = false;
  dataSelected = false;
  error = '';
  actionButtonText = 'Download report';
  headerText = 'Weekly Summary Report';
  years: number[];
  flowPlanTypes: string[];
  constructor(private dialogRef: MatDialogRef<WeeklySummaryReportFilterComponent>, private clientService: ClientService, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    this.flowPlanTypes = this.getFlowPlanTypesForDisplay();

    if (this.data !== null && this.data.overrideAction) {
      this.actionButtonText = this.data.overrideAction;
    }

    if (this.data !== null && this.data.headerText) {
      this.headerText = this.data.headerText;
    }
    this.years = this.generateArrayOfYears();
    this.selectedYear = this.years[0];
    this.loadAdvertisers();
  }

  loadAdvertisers() {
    this.clientService.getClients().subscribe((data) => {
      this.advertisers = data;
    });
  }

  loadAdvertiserBrands() {
    this.clientService.getClientBrands(this.selectedAdvertiser).subscribe((data) => {
      this.brands = data;
    });
  }

  loadAdvertiserProducts() {
    this.clientService.getClientBrandProducts(this.selectedBrand).subscribe((data) => {
      this.products = data;
    });
  }

  setDataSelected() {
    this.dataSelected = true;
  }

  filterData() {
    const selectedBusiness = this.advertisers.find((x) => x.businessEntityId === this.selectedAdvertiser);

    const filter = {
      businessEntityId: this.selectedAdvertiser,
      year: this.selectedYear,
      brandId: this.selectedBrand,
      productId: this.selectedProduct,
      flowplanType: FlowplanType[this.selectedFlowPlanType]
    } as DwWeeklySummaryReportRequest;

    if (selectedBusiness) {
      filter.businessName = selectedBusiness.name;
      filter.fileName = this.getReportname(filter);
    }

    this.validRequest = this.validateFilter(filter);

    if (this.validRequest === true) {
      this.dialogRef.close(filter);
    } else {
      this.error = 'Please select valid values';
    }
  }

  validateFilter(filter: DwWeeklySummaryReportRequest) {
    this.error = '';
    if (filter.businessEntityId === 0) {
      return false;
    }

    if (filter.year === 0) {
      return false;
    }

    if (filter.brandId === 0) {
      return false;
    }

    if (filter.productId === 0) {
      return false;
    }

    if (isNaN(filter.flowplanType)) {
      return false;
    }

    return true;
  }

  private getReportname(body: DwWeeklySummaryReportRequest) {
    return 'Weekly ' + this.selectedFlowPlanType + ' Summary Report ' + body.businessName + ' ' + body.year + '.xlsx';
  }

  private generateArrayOfYears() {
    const max = new Date().getFullYear();
    const min = max - 10;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  getFlowPlanTypesForDisplay() {
    return Object.keys(FlowplanType).filter((type) => isNaN(type as any));
  }

  close() {
    this.dialogRef.close();
  }
}
