<h2 class="g-marg-b-20">{{headerText}}</h2>
<h4 class="g-marg-b-10" *ngIf="error !== ''" style="color:red">{{error}}</h4>


<div class="g-w-full" fxLayout="column">
  <mat-form-field appearance="outline">
    <mat-label>Advertiser</mat-label>
    <mat-select (selectionChange)="loadAdvertiserBrands()" [(ngModel)]="selectedAdvertiser" name="advertiser" [disabled]="advertisers.length == 0">
      <mat-option *ngFor="let advertiser of advertisers" [value]="advertiser.businessEntityId"> {{advertiser.name}} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Brand</mat-label>
    <mat-select (selectionChange)="loadAdvertiserProducts()" [(ngModel)]="selectedBrand" name="brand" [disabled]="selectedAdvertiser === 0">
      <mat-option *ngFor="let brand of brands" [value]="brand.brandId"> {{brand.name}} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Product</mat-label>
    <mat-select [(ngModel)]="selectedProduct" name="product" [disabled]="selectedBrand === 0">
      <mat-option *ngFor="let product of products" [value]="product.productId"> {{product.name}} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Media Type</mat-label>
    <mat-select [(ngModel)]="selectedFlowPlanType" (selectionChange)="setDataSelected()" name="flowPlanType" [disabled]="selectedProduct === 0">
      <mat-option *ngFor="let type of flowPlanTypes" [value]="type"> {{type}} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Year</mat-label>
    <mat-select [(ngModel)]="selectedYear" name="year" [disabled]="selectedProduct === 0">
      <mat-option *ngFor="let year of fiscalYears" [value]="year"> {{year.displayLabel}} </mat-option>
    </mat-select>
  </mat-form-field>

  <div fxLayout="row">
    <button class="g-w-full" mat-flat-button color="primary" (click)="close()">Cancel</button>
    <div fxFlex="10px"></div>
    <button [disabled]="!dataSelected" class="g-w-full" mat-flat-button color="accent" (click)="filterData()">{{actionButtonText}}</button>
  </div>
</div>




