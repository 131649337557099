export enum PlatformViews {
  Desktop = 1,
  Tablet = 2,
  Mobile = 3,
  IFrame = 4
}

export enum FlowplanType {
  //Cinema = 1,
  //Digital = 2,
  Magazine = 3,
  NewsPaper = 4,
  //Misc = 5,
  //OutOfHome = 6,
  Radio = 7,
  TV = 8
  //TVMisc = 9
}
