import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: ''
})

export abstract class UnsubscribeBaseComponent implements OnDestroy {
  public onDestroy: Subject<void> = new Subject();

  constructor() { }

  ngOnDestroy() {
    this.unSubscribe();
  }

  unSubscribe() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
