export interface RouteItem {
  name: string;
  route?: string;
  icon?: string;
  isGroup?: boolean;
  children?: RouteItem[];
}

export class FiscalYearSelectItem {
  fiscalStartYear: number;
  fiscalEndYear: number;
  displayLabel: string;
  displayOrder: number;

  constructor(fiscalStartYear: number, fiscalEndYear: number, displayLabel: string, displayOrder: number) {
    this.fiscalStartYear = fiscalStartYear;
    this.fiscalEndYear = fiscalEndYear;
    this.displayLabel = displayLabel;
    this.displayOrder = displayOrder;
  }
}
