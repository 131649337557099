import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILogin, ILoginIn } from '../contracts/contracts';

@Injectable({ providedIn: 'root' })
export class AccountsProxy {
  constructor(private http: HttpClient) {}

  public ping() {
    return this.http.head<void>('accounts/ping');
  }

  public login(credentials: ILoginIn) {
    return this.http.post<ILogin>('accounts/login', JSON.stringify(credentials));
  }

  public refreshToken(refreshToken: string) {
    return this.http.get<ILogin>('accounts/token/refresh/' + refreshToken);
  }
}
