import { Injectable } from '@angular/core';
import { DashboardProxy } from '../proxies/dashboard.proxy';

@Injectable()
export class DashboardService {
  constructor(private dashboardProxy: DashboardProxy) {}

  public getDigitalDisplayFlowPlanBudget() {
    return this.dashboardProxy.getDigitalDisplayFlowPlanBudget();
  }

  public getDigitalDisplayFlowPlanBudgetByBrand(brand: string) {
    return this.dashboardProxy.getDigitalDisplayFlowPlanBudgetByBrand(brand);
  }

  public getDigitalDisplayFlowPlanBudgetSpent() {
    return this.dashboardProxy.getDigitalDisplayFlowPlanBudgetSpent();
  }

  public getBrands() {
    return this.dashboardProxy.getBrands();
  }

  public getBudgetYears() {
    return this.dashboardProxy.getBudgetYears();
  }

  public getBudgetByYear(fiscalYear: number) {
    return this.dashboardProxy.getBudgetByYear(fiscalYear);
  }

  public getBudgetBrands() {
    return this.dashboardProxy.getBudgetBrands();
  }

  public getBudgetByBrand(brand: string) {
    return this.dashboardProxy.getBudgetByBrand(brand);
  }

  public getBurstRangeCombinations(brand: string, product: string, subproduct: string) {
    return this.dashboardProxy.getBurstRangeCombinations(brand, product, subproduct);
  }
}
