import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ErrorSnackBarComponent } from '../components/error-snack-bar/error-snack-bar';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(private snackBar: ErrorSnackBarComponent) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the call fails, retry first before throwing an error
    return next.handle(request).pipe(
      retry(1),
      catchError(error => this.errorHandler(error))
    );
  }

  private errorHandler(response: any): Observable<HttpEvent<any>> {
    if (response.status === 0) {
      response.message = 'Your network is unavailable. Check your data or wifi connection.';
    } else {
      response.message = 'An error occurred while processing your request. Please try again.';
      if (!environment.production) {
        console.error('Request error', response);
      }
    }

    this.snackBar.openSnackBar(response.message, 'Close', 'red-snackbar');
    throw response;
  }
}
