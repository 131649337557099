import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadCsvService {
  downloadFile(data: any[], filename: string): Promise<void> {
    if (!data.length) {
      return Promise.reject('no data was supplied');
    }

    const url = this.createUrl(this.ConvertToCSV(data, this.getHeaders(data)));
    this.generateDownloadLink(url, filename);

    return Promise.resolve();
  }

  private getHeaders(data: any[]) {
    if (data.length > 0) {
      return Object.keys(data[0]).map((header) => {
        return {
          property: header,
          displayValue: this.upperCaseFirstLetter(header.split(/(?=[A-Z])/).join(' '))
        };
      });
    }
  }

  private upperCaseFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  private createUrl(csvData: string): string {
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    return URL.createObjectURL(blob);
  }

  private ConvertToCSV(
    arrayObj: any,
    headerList: {
      property: string;
      displayValue: any;
    }[]
  ): string {
    const newLine = '\r\n';
    const array = typeof arrayObj !== 'object' ? JSON.parse(arrayObj) : arrayObj;
    const csvString = headerList.map((header) => header.displayValue).join() + newLine;

    return csvString.concat(
      array
        .map((arr: { [x: string]: any }) =>
          Object.keys(headerList).map((headerIndex) => {
            return /(,)/.test(arr[headerList[headerIndex].property]) // if there is a , contained
              ? `\" ${arr[headerList[headerIndex].property]} \"`
              : arr[headerList[headerIndex].property];
          })
        )
        .join(newLine)
    );
  }

  private generateDownloadLink(url: string, filename: string) {
    const downloadLink = document.createElement('a');
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      downloadLink.setAttribute('target', '_blank');
    }

    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('data-cy', 'download-file-link');
    downloadLink.setAttribute('download', filename + '.csv');
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink).click();
    document.body.removeChild(downloadLink);
  }
}
