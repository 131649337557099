import { FiscalYearSelectItem } from '../contracts/common';

export function SpaceOnCaps(value: string) {
  return value.replace(/([A-Z])/g, ' $1').trim();
}

export const ellipseString = (value: string) => {
  if (!value || value?.length < 25) {
    return value;
  }

  return value.substring(0, 25) + '...';
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

// Function to generate select items in fiscal periods (2020/2021), also contains buffer of 3 years
export const generateArrayOfFiscalYears = (fiscalStartDate: Date, fiscalEndDate: Date) => {
  const fiscalYears: FiscalYearSelectItem[] = [];
  const overlappingYear = fiscalStartDate.getFullYear() === fiscalEndDate.getFullYear() ? false : true;
  var fiscalAmountsToGenerate = 0;

  if (fiscalEndDate.getFullYear() > new Date().getFullYear()) {
    fiscalAmountsToGenerate = fiscalEndDate.getFullYear() - (fiscalStartDate.getFullYear() - 3);
  } else {
    fiscalAmountsToGenerate = (overlappingYear === false ? new Date().getFullYear() + 1 : new Date().getFullYear()) - (fiscalStartDate.getFullYear() - 3);
  }

  var startYear = fiscalStartDate.getFullYear() - 3;

  for (var i = 0; i < fiscalAmountsToGenerate; i++) {
    const endYear = startYear + (overlappingYear ? 1 : 0);
    var label = overlappingYear === false ? startYear.toString() : startYear + '/' + endYear;
    fiscalYears.push(new FiscalYearSelectItem(startYear, endYear, label, i));
    startYear++;
  }

  return fiscalYears;
};
