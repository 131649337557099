import { Injectable } from '@angular/core';
import { IGraphFilter } from '../contracts/contracts';
import { TvProxy } from '../proxies/tv.proxy';

@Injectable()
export class TVService {
  constructor(private tvProxy: TvProxy) {}

  public getBrands(type: string) {
    return this.tvProxy.getBrands(type);
  }

  public getProducts(type: string, brand: string) {
    return this.tvProxy.getProducts(type, brand);
  }

  public getSubProducts(type: string, product: string) {
    return this.tvProxy.getSubProducts(type, product);
  }

  public getGRPByWeek(filter: IGraphFilter) {
    return this.tvProxy.getGRPByWeek(filter);
  }
  public getReachPercByWeek(filter: IGraphFilter) {
    return this.tvProxy.getReachPercByWeek(filter);
  }
  public getGRPByStation(filter: IGraphFilter) {
    return this.tvProxy.getGRPByStation(filter);
  }
  public getGRPByPIBPerc(filter: IGraphFilter) {
    return this.tvProxy.getGRPByPIBPerc(filter);
  }
  public getGRPByDayPart(filter: IGraphFilter) {
    return this.tvProxy.getGRPByDayPart(filter);
  }
}
