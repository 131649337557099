import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { UnsubscribeBaseComponent } from './core/components/base';

@Component({
  selector: 'app-root',
  template: ` <div class="mat-typography" style="height:100%"><router-outlet></router-outlet></div> `
})
export class AppComponent extends UnsubscribeBaseComponent implements OnDestroy {
  public constructor(private titleService: Title) {
    super();
    this.titleService.setTitle('Media Cogent DW - v' + environment.version);
  }
}
