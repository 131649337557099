import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BurstRange, GraphDataType, GraphDataTypeList } from '../contracts/contracts';

@Injectable({ providedIn: 'root' })
export class DashboardProxy {
  constructor(private http: HttpClient) {}

  public getDigitalDisplayFlowPlanBudget() {
    const url = `dashboard/digital/display/flowplan/budget`;
    return this.http.get<GraphDataTypeList[]>(url);
  }

  public getDigitalDisplayFlowPlanBudgetByBrand(brand: string) {
    const url = `dashboard/digital/display/flowplan/budget/${brand}`;
    return this.http.get<GraphDataTypeList[]>(url);
  }

  public getDigitalDisplayFlowPlanBudgetSpent() {
    const url = `dashboard/digital/display/flowplan/budget/spent`;
    return this.http.get<GraphDataType[]>(url);
  }

  public getBrands() {
    const url = `dashboard/brands`;
    return this.http.get<string[]>(url);
  }

  public getBudgetYears() {
    const url = `dashboard/budget/years`;
    return this.http.get<number[]>(url);
  }

  public getBudgetByYear(fiscalYear: number) {
    const url = `dashboard/budget/year/${fiscalYear}`;
    return this.http.get<GraphDataType[]>(url);
  }

  public getBudgetBrands() {
    const url = `dashboard/budget/brands`;
    return this.http.get<string[]>(url);
  }

  public getBudgetByBrand(brand: string) {
    const url = `dashboard/budget/brand/${brand}`;
    return this.http.get<GraphDataTypeList[]>(url);
  }

  public getBurstRangeCombinations(brand: string, product: string, subproduct: string) {
    const url = `dashboard/burst/range/combinations?brand=${brand}&product=${product}&subproduct=${subproduct}`;
    return this.http.get<BurstRange[]>(url);
  }
}
