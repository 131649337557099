import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  template: `
    <div class="startup">
      <img class="img" src="assets/img/logo.png" />
      <h4>You are not authorized for this route</h4>
      <br />
      <div style="width:200px" fxLayout="row" fxLayoutAlign="space-around start">
        <button (click)="login()" mat-raised-button color="primary">Back</button>
        <button (click)="login()" mat-raised-button color="primary">Home Page</button>
      </div>
    </div>
  `
})
export class UnauthorizedComponent {
  constructor(private router: Router, private location: Location) {}

  login() {
    this.router.navigate(['']);
  }
  back() {
    this.location.back();
  }
}
