import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { CoreModule } from './core/core.module';
import { AppLayoutComponent } from './layout/app/app';
import { LoginLayoutComponent } from './layout/login/login';
import { PageNotFoundComponent } from './not-found.component';
import { SharedModule } from './shared/shared.module';
import { UnauthorizedComponent } from './unauthorized.component';

@NgModule({
  declarations: [AppLayoutComponent, LoginLayoutComponent, AppComponent, UnauthorizedComponent, PageNotFoundComponent],
  imports: [BrowserAnimationsModule, SharedModule, CoreModule.forRoot(), RouterModule.forRoot(ROUTES)],
  exports: [BrowserAnimationsModule, CoreModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
